// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-top{
    padding: 10px;
}
.page-bottom{
    padding: 10px;
}
.imagecontainer{
    padding: 10px;
    border: solid 1px lightgrey;
    border-radius: 10px;
    text-align: center;
}
.filename{
    padding: 10px;
    background: antiquewhite;
    border-radius: 10px;
}
.button-container{
    padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/ViewReports.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,wBAAwB;IACxB,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".page-top{\r\n    padding: 10px;\r\n}\r\n.page-bottom{\r\n    padding: 10px;\r\n}\r\n.imagecontainer{\r\n    padding: 10px;\r\n    border: solid 1px lightgrey;\r\n    border-radius: 10px;\r\n    text-align: center;\r\n}\r\n.filename{\r\n    padding: 10px;\r\n    background: antiquewhite;\r\n    border-radius: 10px;\r\n}\r\n.button-container{\r\n    padding-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
