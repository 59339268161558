import React, { useState, useCallback } from 'react';
import { verifyReportById, MAINURL } from './service/api';
import { SmileOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, notification, Upload, message, Input, Row, Col,Image } from 'antd';
import "./VerifyImage.css";
import { useNavigate } from 'react-router-dom'


const VerifyImage = () => {
    const [imageUrl, setImageUrl] = useState("");

    const [serial, setSerial] = useState("");

    const handleVerify = async() => {
        if(serial == ""){
            notification.open({
                message: "Please enter Report Id.",
            });
            setImageUrl("")
        }else{
            const resData = await verifyReportById(serial);
        if(resData.data.length > 0){
            setImageUrl(resData.data[0]?.file)
            notification.open({
                message: "Verified.",
            });
        }else{
            notification.open({
                message: "No Result Found.",
            });
            setImageUrl("")
        }
        }
        
        
    }
  return (
    <>
     
      <Row>
        <Col md={8} sm={8} xs={8}>
        </Col>
        <Col md={8} sm={8} xs={8}>
          <div className='imageHeader'>
            <h2>Verify Image</h2>
          </div>
        </Col>
        <Col md={8} sm={8} xs={8} >
        </Col>
      </Row>

      <Row>
        <Col md={8} sm={2} xs={2}>

        </Col>
        <Col md={8} sm={20} xs={20}>
          <div className="info">
            <div className="ImageContainer">
                { imageUrl == "" ? <div style={{padding:"10px",position:"relative",top:"40%"}}>
                    <label >Please enter the Report Id below to view Image.</label>
                </div>
                :
                <Image
                  src={MAINURL+"public/"+imageUrl}
                  alt="Search Result"
                  style={{
                    maxHeight: '200px',
                    maxWidth:'100%'
                  }}
                /> 
                }
                
               
              
            </div>
            <div>
              {/* <img id="blah" src="#" alt="your image" /> */}
            </div>
            
            {/* <input type="text" name="serialnumber" placeholder="Enter Report Id" /> */}

          </div>
          <Row>
            <Col md={16} sm={16} xs={16}>
            <Input placeholder="Enter Report Id" 
            // value={serial} 
            required={true}
            onChange={(e) => { setSerial(e.target.value) }} 
            />
            </Col>
            <Col md={8} style={{paddingLeft:"5px"}} sm={8} xs={8}>
            <Button style={{width:"100%"}} type="primary" 
            onClick={handleVerify}
            >Verify</Button>
            </Col>
          </Row>
        </Col>
        <Col md={8} sm={2} xs={2}>

        </Col>
      </Row>
      <div>

      </div>
      

    </>
  )

}

export default VerifyImage;
