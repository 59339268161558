import React, { useEffect, useState } from "react";
import { Table, Row, Col, Modal, notification, Input,Checkbox } from "antd";
// import AddUser from "./AddUser";
import EditReport from "./EditReport";
// import ManagePermission from "./ManagePermission";
import "./ViewReports.css";
import{getAllReports,deleteReport} from './service/api'
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    SettingOutlined
} from '@ant-design/icons';


const ViewReports = () => {

    const { confirm } = Modal;

    // State to store User data
    const [userData, setUserData] = useState([]);

    // State to store filtered Data on Search
    const [filterData, setFilterData] = useState([]);


    // State to get new data 
    const [getNew, setGetNew] = useState(true);

    // State for Add User Drawer
    const [visible, setVisible] = useState(false);

    // State for Edit User Drawer
    const [editVisible, setEditVisible] = useState(false);

      // State for Permission Drawer
      const [permissionVisible, setPermissionVisible] = useState(false);

    // State to search the table
    const [nameSearch, setNameSearch] = useState("");

    // user for permission
    const [userPermission, setUserPermission] = useState([""]);

    

    // close Button (X) for Add User
    const closeHandler = () => {
        setVisible(false);
        setEditVisible(false);
        setPermissionVisible(false);
        localStorage.removeItem("uid");
    };

    
   
    // Visible function for Add User
    const visibleHandler = () => {
        setVisible(true);
    };

    // Visible function for Edit User
    const visibleHandlerEdit = (id) => {
        console.log("Id", id);
        setEditVisible(true);
        // setUserId(id);
        localStorage.setItem("uid", id);
    };

    

    // Get the list of all users
    useEffect(() => {
        getReports()
    }, [getNew])

    const getReports = async () => {
        let users = await getAllReports();
        console.log("Users Data", users.data);
        setUserData(users.data);
        setFilterData(users.data);
    }
    
    
    // Function to search the Table
    useEffect(() => {
        if (userData) {
            const results = userData.filter(
                (item) =>
                    item.file.toLowerCase().includes(nameSearch.toLowerCase()) ||
                    item.serial.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setFilterData(results);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameSearch])

    // Modal to delete the User
    const showConfirm = (id,file) => {
        confirm({
            title: 'Do you Want to delete this report?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                deleteReport({id,file}).then(() => {
                    const notify = () => {
                        notification.open({
                            message: "Report Deleted",
                        });
                    };
                    notify();
                    setGetNew(!getNew);
                })
            },
            onCancel() {
                console.log('Cancel', id);
            },
        });
    }

    // User Table Columns
    const columns = [
        {
            title: "Serial No",
            key: 'index',
            render : (text, record, index) => index+1,
        },
        {
            title: "Report ID",
            dataIndex: "serial",
            key: "serial",
            sorter: (a, b) => a.serial.localeCompare(b.serial),
        },
        {
            title: "File Name",
            dataIndex: "file",
            key: "file",
            sorter: (a, b) => a.file.localeCompare(b.file),
        },
        
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (text, record) =>
                <>
                    <div>
                        <EditOutlined
                            onClick={() => { visibleHandlerEdit(record.id) }}
                            style={{ cursor: "pointer", color: "blue", paddingRight: "20px" }} />
                        <DeleteOutlined
                            onClick={() => { showConfirm(record.id,record.file) }}
                            style={{ cursor: "pointer", color: "red",paddingRight: "20px" }} />
                    </div>

                </>

        },
    ];

    // Function to set nameSearch
    const handleOnChange = (event) => {
        setNameSearch(event.target.value);
    };

    // Function to get new Data after Add / Edit
    const getNewData = () => {
        setGetNew(!getNew);
    }
// Function to show toaster after update success
const openNotification = (msg) => {
    notification.open({
        message: msg,
    });
    setPermissionVisible(false);
};

    return (

        <>
            <div className="page-top">
                <Row>
                    <Col md={14}  sm={10} xs={10}>
                        <div style={{textAlign:"left"}}><span>List of Reports</span></div>
                        
                    </Col>
                    <Col md={10}  sm={14} xs={14} style={{ textAlign: "end" }}>
                        <div className="searchForm">
                            <form>
                                <Input.Search
                                    allowClear
                                    onChange={handleOnChange}
                                    placeholder="Search by name / empId / role / designation / email"
                                />
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="page-bottom">
                {/* <AddUser
                    getUsers={getUsers}
                    visible={visible}
                    onClose={closeHandler}
                    onCancelButton={closeHandler}
                /> */}

                <EditReport
                    open={editVisible}
                    onClose={closeHandler}
                    onCancelButton={closeHandler}
                    newData={getNewData}
                /> 
                <Table
                    columns={columns}
                    dataSource={filterData}
                    bordered
                    className="usersTable"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "40", "50"],
                    }}
                    rowKey={"id"}
                />
            </div>
            
        </>


    );
}

export default ViewReports