// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageContainer{
    border: solid 1px lightgray;
    height: 200px;
    border-radius: 10px;
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/VerifyImage.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".ImageContainer{\r\n    border: solid 1px lightgray;\r\n    height: 200px;\r\n    border-radius: 10px;\r\n    margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
