// Dashboard.jsx

import React from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import FileUpload from './FileUpload';
import ViewReports from './ViewReports';
import {
  FileAddOutlined,
  FileSearchOutlined,
  BarChartOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Routes, Route, useNavigate } from 'react-router-dom'
import logo from '../assets/image/reporter.PNG'
import "./Dashboard.css";
const { Header, Content, Sider } = Layout;

const Dashboard = (props) => {
    let navigate = useNavigate();
    const handleLogout =()=>{
        sessionStorage.clear();
        props.setIsLoggedIn(false);
        navigate("/admin");
    }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={150} theme="dark">
        <div style={{  margin: '5px 0px 0px 0px' }} >
            <img src={logo} width={130}/>
        </div>
        <Menu theme="dark" mode="vertical" defaultSelectedKeys={['1']} style={{textAlign:"left",fontSize:"12px"}} onClick={({ key }) => { navigate(key) }}>
          <Menu.Item key={"/admin"} icon={<FileAddOutlined />}>Home</Menu.Item>
          <Menu.Item key={"/admin/viewReport"} icon={<FileSearchOutlined />}>View Reports</Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
            <div style={{textAlign:"right",marginRight:"15px"}}>
            <Tooltip placement="leftBottom" title={"Logout"}>
            <LogoutOutlined className='logoutbutton' onClick={handleLogout}/>
            </Tooltip>
            </div>
        </Header>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            {/* Your dashboard content goes here */}
            <Routes>
                    <Route path='/admin' element={<FileUpload/>} />
                    <Route path='/admin/viewReport' element={<ViewReports/>} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
