// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background: linear-gradient(to right, #ff6f61, #f9d423);
  }
  
  .formContainer {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: auto;
  }
  
  .label {
    color: #333;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .input {
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .button {
    background-color: #3498db;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    font-size: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoginPage.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uDAAuD;EACzD;;EAEA;IACE,0CAA0C;IAC1C,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":["/* styles.css */\r\n\r\n.container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-size: cover;\r\n    background: linear-gradient(to right, #ff6f61, #f9d423);\r\n  }\r\n  \r\n  .formContainer {\r\n    background-color: rgba(255, 255, 255, 0.8);\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .logo {\r\n    width: 150px;\r\n    height: 150px;\r\n    border-radius: 50%;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .title {\r\n    color: #333;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    max-width: 300px;\r\n    margin: auto;\r\n  }\r\n  \r\n  .label {\r\n    color: #333;\r\n    margin-bottom: 10px;\r\n    text-align: left;\r\n  }\r\n  \r\n  .input {\r\n    padding: 8px;\r\n    margin-bottom: 10px;\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n  }\r\n  \r\n  .button {\r\n    background-color: #3498db;\r\n    color: #ffffff;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n    border: none;\r\n    border-radius: 3px;\r\n    font-size: 16px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
