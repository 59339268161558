import React, { useState } from 'react';
import './LoginPage.css'; // Import the external CSS file
import { login } from './service/api';

const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [backendError, setBackendError] = useState("");

  const handleLogin = () => {
    const loginData = {
      username : username,
      password : password
    }
    login(loginData).then((response) => {
      //console.log("a",response.data);
      if(response.data.email){
        sessionStorage.setItem("username", response.data.email);
        props.setIsLoggedIn(true);
      }else{
        setBackendError(response.data);
      }
    })
    // Implement your login logic here
    //console.log(`Logging in with username: ${username} and password: ${password}`);
  };

  return (
    <div className="container">
      <div className="formContainer">
        <h2 className="title">Login</h2>
        <form className="form">
          <label className="label">
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input"
            />
          </label>
          <label className="label">
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input"
            />
          </label>
          <button type="button" onClick={handleLogin} className="button">
            Login
          </button>
        </form>
        <p className="form-error">{backendError}</p>
      </div>
    </div>
  );
};

export default LoginPage;
