import React, { useState, useCallback } from 'react';
import { uploadFile, MAINURL } from './service/api';
import { SmileOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, notification, Upload, message, Input, Row, Col } from 'antd';
import "./FileUpload.css";
import { useNavigate } from 'react-router-dom'


const FileUpload = () => {
  let navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [attachedFiles, setAttachedFiles] = useState("");
  const [serial, setSerial] = useState("");
  const [messageApi, messageHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  
  const getBase64 = (img, callback) => {
    console.log("ic", callback);
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      // console.log('You can only upload JPG/PNG file!');
      messageApi.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      // console.log('Image must smaller than 5MB!');
      messageApi.error('Image must smaller than 5MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const openNotification = (message,desc) => {
    api.open({
      message: message,
      description: desc
        ,
      icon: <SmileOutlined style={{ color: '#108ee9' }} rotate={message == "Upload Failed"?"180":"0"}/>,
    });
  };

  const handleSubmit = async () => {
    if(serial == "" || attachedFiles == ""){
      notification.open({message:"Report id and image are mandatory"})
    }else{
      const formData = new FormData();
    formData.append("file", attachedFiles);
    formData.append("id", serial);
    await uploadFile(formData).then((res) => {
      if (res.data == "success") {
        openNotification('Upload Successful','The Image has been uploaded Successfully');
        setImageUrl(false);
      setLoading(false);
      setSerial("");
      setAttachedFiles("");
      } else {
        console.log("failed")
      }
    })
    }
    
  }

  //console.log("serial", serial);
 
  const handleChange = (info) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      setAttachedFiles(info.file.originFileObj);
    }
    if(info.file.status === 'error'){
      openNotification('Upload Failed',"Please try again with a different file.");
      setImageUrl(false);
      setLoading(false);
    }
    
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      {contextHolder}
      {messageHolder}
      <Row>
        <Col md={8}>
        </Col>
        <Col md={8} >
          <div className='imageHeader'>
            <h2>Upload Image</h2>
          </div>
        </Col>
        <Col md={8}>
        </Col>
      </Row>

      <Row>
        <Col md={8}>

        </Col>
        <Col md={8}>
          <div className="info">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              multiple={false}
              showUploadList={false}
              action={MAINURL+"mockImage"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    maxHeight: '100%',
                    maxWidth:'100%'
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <div>
              {/* <img id="blah" src="#" alt="your image" /> */}
            </div>
            
            {/* <input type="text" name="serialnumber" placeholder="Enter Report Id" /> */}

          </div>
          <Row>
            <Col md={16}>
            <Input placeholder="Enter Report Id" value={serial} onChange={(e) => { setSerial(e.target.value) }} />
            </Col>
            <Col md={8} style={{paddingLeft:"5px"}}>
            <Button style={{width:"100%"}} type="primary" onClick={handleSubmit}>Submit</Button>
            </Col>
          </Row>
        </Col>
        <Col md={8}>

        </Col>
      </Row>
      <div>

      </div>
      

    </>
  )

}

export default FileUpload;
