import axios from "axios";

 //export const MAINURL = "http://localhost:3005/";
export const MAINURL = "https://backend.igji.in/"

// admin Login

export const login = async(body) => {
    return await axios.post(`${MAINURL}login`, body)
}

// Add image to db
export const uploadFile = async (body) => {
    return await axios.post(`${MAINURL}uploadImage`, body)
}

// GET All reports from DB
export const getAllReports = async () => {
    return await axios.get(`${MAINURL}getReports`);
}

// GET reports by id
export const getReportById = async (id) => {
    return await axios.get(`${MAINURL}getReports/${id}`);
}

// verify reports by id
export const verifyReportById = async (id) => {
    return await axios.get(`${MAINURL}verifyReports/${id}`);
}

// edit report to db
export const updateReport = async (body) => {
    return await axios.post(`${MAINURL}updateReport`, body)
}

// delete report from db
export const deleteReport = async (body) => {
    return await axios.post(`${MAINURL}deleteReport`, body)
}