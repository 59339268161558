// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageHeader{
    color: #1677ff;
}

.ant-upload-select{
    width: 100% !important;
    height: 150px !important;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileUpload.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,wBAAwB;IACxB,gBAAgB;AACpB","sourcesContent":[".imageHeader{\r\n    color: #1677ff;\r\n}\r\n\r\n.ant-upload-select{\r\n    width: 100% !important;\r\n    height: 150px !important;\r\n    overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
