import React, {useState, useEffect, useLocation, useHistory } from "react";
import LoginPage from "./LoginPage";
import Dashboard from "./Dashboard";
// import UserImage from "../assets/images/user.png";
// import { Layout, Row, Col } from 'antd';
import VerifyImage from "./VerifyImage";
import { Routes, Route, useNavigate } from 'react-router-dom'
// import { Menu, Dropdown, Button } from 'antd';
// import { UserOutlined, LogoutOutlined, MenuUnfoldOutlined } from '@ant-design/icons';



// const { Content, Sider, Header } = Layout;

const HomePage = () => {


    let navigate = useNavigate();

    // State to check if User is Logged In
    //let loggedIn = sessionStorage.getItem("isLoggedIn")
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const [permission, setPermission] = useState([]);
    // const [role, setRole] = useState("");
   



    useEffect(() => {
        let userId = sessionStorage.getItem("username");

        if (userId == "" || !userId) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }

    }, [])

    return (
        <>
            {isLoggedIn ? (
                <Dashboard
                setIsLoggedIn={setIsLoggedIn}
                />
            ) :
                <Routes>
                    <Route path='/admin/*' element={<LoginPage
                        setIsLoggedIn={setIsLoggedIn}
                    // sendDataToParent={sendDataToParent}
                    // dataFromLogin={dataFromLogin}
                    />} />
                    <Route path='/' element={<VerifyImage/>} />
                </Routes>
            }

        </>
    );
}

export default HomePage;