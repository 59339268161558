import React, { useEffect, useState } from "react";
import { Drawer, Input, Button, Image } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { notification } from "antd";
import { MAINURL, getReportById,updateReport } from "./service/api";

// import { editReport } from "../../../services/api";

const EditReport = (props) => {

    const [attachedFiles, setAttachedFiles] = useState("");

    const onInputChange = (e) => {
        setAttachedFiles(e.target.files[0]);
    };

    
    // Edit User form validation
    const validationSchema = yup.object({
        username: yup.string().min(4).required("Please enter the Username"),
        name: yup.string().required("Please enter the User Name"),
    });

    // Function to show toaster after update success
    const openNotification = () => {
        notification.open({
            message: "Report Updated Successfully",
        });
    };

    let id = localStorage.getItem("uid");
    const initialValues = {
        id: id,
        file: "",
        serial: ""
    }


    const [reportData, setReportData] = useState([initialValues]);

    console.log(reportData)
    // Get the report by Id
    useEffect(() => {
        if (id) {
            getReportById(id).then((res) => {
                console.log("Single User", res.data);
                setReportData(res.data);
            })
        }
    }, [id]);


    //console.log("Single User", userData[0]);

    // Set the data to empty when cancel is clicked
    const onCancelHandler = () => {
        props.onCancelButton();
        props.onClose();
        setReportData("")
        //localStorage.removeItem("userId");
    };


    return (
        <>
            <Drawer
                title="Update Report"
                width={500}
                onClose={onCancelHandler}
                open={props.open}
                className="edit_user"
                closable={false}
                maskClosable={false}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        id: Number(id),
                        file: reportData[0]?.file,
                        serial: reportData[0]?.serial,
                    }}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const formData = new FormData();
                        formData.append("file", attachedFiles);
                        formData.append("serial", values.serial);
                        formData.append("id", values.id);
                        formData.append("oldFile", values.file);
                        console.log("values", formData);
                        updateReport(formData)
                            .then((response) => {
                                console.log(response.data);
                                openNotification();
                                props.onClose();
                                props.newData();
                            })
                            .catch(function (error) {
                                console.log(error.response.data);
                                const notify = () => {
                                    notification.open({
                                        message: error.response.data.status.message,
                                    });
                                };

                                notify();

                            });
                    }}
                >
                    {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="add_project_form">
                            <label>Report Id</label>
                            <Input
                                placeholder="Enter the Update Id"
                                name="serial"
                                onChange={handleChange}
                                value={values.serial}
                                className="input--field"
                            ></Input>
                            <p className="display_error">{errors.serial}</p>
                            <div className="imagecontainer">
                                <div><Image
                                    height={200}
                                    src={values.file?MAINURL + "public/" + values.file:""}
                                />
                                </div>
                                <div className="filename">
                                    <label>{values.file}</label>
                                </div>

                            </div>
                            <div style={{ paddingTop: "10px" }}>
                                <label>Change Image : </label>
                                <input type="file"
                                    style={{ float: "right" }}
                                    placeholder="choose file"
                                    accept=".jpg, .png, .jpeg"
                                    onChange={onInputChange}
                                    name="filename1"
                                />
                                <p className="display_error">{errors.file}</p>
                            </div>


                            <div className="button-container">
                                <Button
                                    type="primary"
                                    className="primary-btn"
                                    htmlType="submit"
                                >Update</Button> {"  "}
                                <Button
                                    type="primary"
                                    className="primary-btn cancel--btn"

                                    onClick={onCancelHandler}
                                >Cancel</Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
}

export default EditReport;